import { Route, Routes } from "react-router-dom";
import "../App/App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Training from "../Training/Training";
import Services from "../Services/Services";
import Reviews from "../Reviews/Reviews";
import Form from "../Form/Form";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../Footer/Footer";
import Popup from "../Popup/Popup";
import Cookies from "../Cookies/Cookies";
import Privacy from "../Privacy/Privacy";
import ScrollToTop from "../Scroll/Scroll";
import Britain from "../Britain/Britain";
import Italy from "../Italy/Italy";
import Germany from "../Germany/Germany";
import France from "../France/France";


function App() {
  return (
    <div className="vyh-page">
      <ScrollToTop />
      <Header />
      <Popup />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/training" element={<Training />} />
        <Route path="/our-services" element={<Services />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/form" element={<Form />} />
        <Route path="/britain" element={<Britain />} />
        <Route path="/italy" element={<Italy />} />
        <Route path="/germany" element={<Germany />} />
        <Route path="/france" element={<France />} />
        <Route path="/netherlands" element={<Britain />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
