import React from "react";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import "./Reviews.css";

const Reviews = () => {
  const location = useLocation();

  return (
    <section className="vyh-reviews">
      {location.pathname === "/reviews" ? (
        <h1 className="vyh-reviews__header">reviews</h1>
      ) : (
        <h2 className="vyh-reviews__header">reviews</h2>
      )}
      <Swiper
        slidesPerView={1}
        navigation={true}
        modules={[Navigation]}
        className="vyh-reviews__swiper"
        loop={true}
      >
        <SwiperSlide className="vyh-reviews__swiper-slide">
          <div className="vyh-reviews__left-container">
            <div className="vyh-reviews__text">
              <h2 className="fw-400 col-white">Jan Novák</h2>
              <p className="t-20 col-white">
                Školení v této škole bylo nejlepší volbou pro mou kariéru.
                Lektoři jsou zkušení a vstřícní, vysvětlují všechny aspekty
                práce krupiéra. Praktická cvičení u reálných herních stolů mi
                pomohla získat dovednosti rychleji, než jsem očekával.
                Doporučuji všem, kdo se chtějí stát profesionály v kasinovém
                průmyslu.
              </p>
            </div>
          </div>
          <div className="vyh-reviews__img-container img-1"></div>
        </SwiperSlide>
        <SwiperSlide className="vyh-reviews__swiper-slide">
          <div className="vyh-reviews__left-container">
            <div className="vyh-reviews__text">
              <h2 className="fw-400 col-white">Anna Dvořáková</h2>
              <p className="t-20 col-white">
                Absolvovala jsem kurz v této škole a jsem velmi spokojená. Zde
                se nejen učí teoretické znalosti, ale klade se také velký důraz
                na praxi. Líbilo se mi, že jsme se hodně věnovali technikám
                komunikace se zákazníky a vytváření příjemné atmosféry u herního
                stolu. Je to opravdu užitečná zkušenost!
              </p>
            </div>
          </div>
          <div className="vyh-reviews__img-container img-2"></div>
        </SwiperSlide>
        <SwiperSlide className="vyh-reviews__swiper-slide">
          <div className="vyh-reviews__left-container">
            <div className="vyh-reviews__text">
              <h2 className="fw-400 col-white">Petr Svoboda</h2>
              <p className="t-20 col-white">
                Škola nabízí skvělé výukové programy s vysoce kvalifikovanými
                lektory. Kurzy byly intenzivní, ale právě díky tomu jsem získal
                všechny potřebné dovednosti. Nyní se cítím jistý u herního stolu
                a jsem připraven pracovat v jakémkoli kasinu. Velké díky všem
                lektorům za jejich práci!
              </p>
            </div>
          </div>
          <div className="vyh-reviews__img-container img-3"></div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Reviews;
