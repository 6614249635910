import React from "react";
import Destinations from "../Destinations/Destinations";
import "../Britain/Country.css";
import Grid from "../Grid/Grid";
import img1 from "../../images/france-1.webp";
import img2 from "../../images/france-2.webp";
import img3 from "../../images/france-3.webp";
import img4 from "../../images/france-4.webp";
import img5 from "../../images/france-5.webp";

const France = () => {
  return (
    <>
      <section className="vyh-country">
        <h1 className="vyh-country__header text-center col-white">
          Top 5 Nejlepší kasino Dealer školy ve Francii.
        </h1>
      </section>
      <Destinations />
      <section className="vyh-casinos">
        <h2 className="vyh-casinos__header">Casinos</h2>
        <div className="vyh-casinos__body">
          <ul className="vyh-casinos__list">
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Paris Casino School
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Paris Casino School je známá škola nabízející kurzy pro krupiéry
                s důrazem na francouzskou ruletu a poker. Škola poskytuje
                praktickou výuku u herních stolů.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Lyon Dealer Academy
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Lyon Dealer Academy se zaměřuje na výuku základních her a
                dovedností potřebných pro krupiéry. Kurzy zahrnují blackjack,
                baccarat a pravidla etiky práce v kasinu.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Marseille Gaming Institute
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Marseille Gaming Institute nabízí školení pro krupiéry a
                manažery kasin. Programy zahrnují kurzy řízení a školení ve
                strategii různých her.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Nice Casino Training Centre
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Nice Casino Training Centre připravuje specialisty pro práci v
                kasinu se zaměřením na praktickou výuku a techniky komunikace se
                zákazníky.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Toulouse Professional Casino School
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Toulouse Professional Casino School nabízí kurzy pro krupiéry se
                zaměřením na francouzskou kasinovou kulturu a unikátní metody
                práce u herních stolů.
              </p>
            </li>
          </ul>
          <Grid
            name={"Casino Barrière de Deauville"}
            img1={img1}
            img2={img2}
            img3={img3}
            img4={img4}
            text="Casino Barrière de Deauville nabízí školení pro krupiéry se zaměřením na francouzskou ruletu a baccarat. Program zahrnuje praktická cvičení s profesionálními instruktory."
            img5={img5}
          />
        </div>
      </section>
    </>
  );
};

export default France;
