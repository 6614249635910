import React from "react";

const Cookies = () => {
  return (
    <main>
      <section className="vyh-privacy">
        <h1 className="privacy__header">
          Zásady používání souborů cookie pro vyhernipecky.com
        </h1>
        <p className="t-20">
          Toto jsou zásady používání souborů cookie pro vyhernipecky.com,
          přístupné prostřednictvím vyhernipecky.com.
        </p>
        <h2 className="privacy__header">Co jsou soubory cookie</h2>
        <p className="t-20">
          Stejně jako je běžné u téměř všech profesionálních webových stránek, i
          tento web používá soubory cookie, což jsou malé soubory stažené do
          vašeho počítače, aby vylepšily vaši zkušenost. Tato stránka popisuje,
          jaké informace shromažďujeme, jak je používáme a proč někdy musíme
          tyto soubory cookie ukládat. Také vysvětlíme, jak můžete zabránit
          ukládání těchto souborů cookie, ale to může ovlivnit nebo „rozbít“
          určité funkce webu.
        </p>
        <h2 className="privacy__header">Jak používáme soubory cookie</h2>
        <p className="t-20">
          Používáme soubory cookie z různých důvodů, které jsou popsány níže.
          Bohužel ve většině případů neexistují v rámci odvětví standardizované
          možnosti, jak zakázat soubory cookie bez úplného vypnutí funkcí a
          vlastností, které přidávají tomuto webu. Doporučuje se ponechat
          všechny soubory cookie povolené, pokud si nejste jisti, zda je
          potřebujete či nikoli, v případě, že jsou používány k poskytování
          služby, kterou využíváte.
        </p>
        <h2 className="privacy__header">Zakázání souborů cookie</h2>
        <p className="t-20">
          Můžete zabránit nastavování souborů cookie úpravou nastavení svého
          prohlížeče (více informací naleznete v nápovědě svého prohlížeče).
          Uvědomte si, že zakázání souborů cookie ovlivní funkčnost tohoto webu
          i mnoha dalších webů, které navštěvujete. Zakázání souborů cookie
          obvykle také deaktivuje některé funkce a vlastnosti tohoto webu. Proto
          se doporučuje soubory cookie nezakazovat.
        </p>
        <h2 className="privacy__header">Soubory cookie, které nastavujeme</h2>
        <p className="t-20">
          Soubory cookie používané pro nastavení webu. Abychom vám zajistili
          příjemný zážitek na tomto webu, nabízíme funkci nastavení vašich
          preferencí ohledně způsobu, jakým tento web funguje při jeho
          používání. Abychom si vaše preference zapamatovali, musíme nastavit
          soubory cookie, aby tyto informace mohly být přístupné pokaždé, když
          interagujete s stránkou, na kterou mají vaše preference vliv.
        </p>
        <h2 className="privacy__header">Soubory cookie třetích stran</h2>
        <p className="t-20">
          V některých zvláštních případech používáme také soubory cookie
          poskytované důvěryhodnými třetími stranami. Následující část popisuje,
          jaké soubory cookie třetích stran můžete na tomto webu potkat.
        </p>
        <p className="t-20">
          Tento web používá Google Analytics, jedno z nejrozšířenějších a
          nejdůvěryhodnějších řešení pro webovou analytiku, které nám pomáhá
          porozumět tomu, jak používáte náš web, a jak můžeme zlepšit váš
          zážitek. Tyto soubory cookie mohou sledovat věci, jako je čas, který
          strávíte na webu, a jaké stránky navštěvujete, abychom mohli
          pokračovat v produkci relevantního a poutavého obsahu.
        </p>
        <p className="t-20">
          Pro více informací o souborech cookie Google Analytics navštivte
          oficiální stránku Google Analytics.
        </p>
        <h2 className="privacy__header">Více informací</h2>
        <p className="t-20">
          Doufáme, že vám tyto informace poskytly jasnost, a jak jsme již
          zmínili, je bezpečnější nechat soubory cookie povolené, pokud si
          nejste jisti, zda je potřebujete, v případě, že interagují s jednou z
          funkcí, které používáte na našem webu.
        </p>
      </section>
    </main>
  );
};

export default Cookies;
