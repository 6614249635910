import React from 'react';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import './Destinations.css';


const Destinations = () => {
  return (
    <div className="vyh-destinations">
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          breakpoints={{
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
              loop: true,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
              loop: false,
            },
          }}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="vyh-destinations__swiper"
        >
          <SwiperSlide className="vyh-destinations__swiper-slide">
            <Link to="/britain" className="t-25 fw-500 col-yellow">
              Británie
            </Link>
          </SwiperSlide>
          <SwiperSlide className="vyh-destinations__swiper-slide">
            <Link to="/italy" className="t-25 fw-500 col-yellow">
              Itálie
            </Link>
          </SwiperSlide>
          <SwiperSlide className="vyh-destinations__swiper-slide">
            <Link to="/germany" className="t-25 fw-500 col-yellow">
              Německo
            </Link>
          </SwiperSlide>
          <SwiperSlide className="vyh-destinations__swiper-slide">
            <Link to="/france" className="t-25 fw-500 col-yellow">
              Francie
            </Link>
          </SwiperSlide>
          <SwiperSlide className="vyh-destinations__swiper-slide">
            <Link to="/netherlands" className="t-25 fw-500 col-yellow">
              Nizozemsko
            </Link>
          </SwiperSlide>
        </Swiper>
      </div>
  )
}

export default Destinations