import React from "react";
import { Link } from "react-router-dom";
import "./Main.css";
import Destinations from "../Destinations/Destinations";
import Training from "../Training/Training";
import Services from "../Services/Services";
import Reviews from "../Reviews/Reviews";
import Form from "../Form/Form";

import MainPopup from "../Popup/MainPopup";

const MainCover = () => {
  return (
    <main className="vyh-main">
      {/* <MainPopup/> */}
      <section className="vyh-main__cover">
        <h1 className="vyh-main__header text-center">
          Nejlepší, nové a legální školy pro krupiéry: Vaše cesta k úspěchu
        </h1>
        <p className="text-center vyh-main__text t-25 col-white">
          Hledáte školu pro výuku profese prodejce kasin? Recenze nejlepších
          vzdělávacích institucí, abyste se mohli správně rozhodnout a začít
          kariéru v zábavním průmyslu.
        </p>
        <Link to="/form" className="vyh-main__link t-25">
          Stát se krupiérem
        </Link>
      </section>
      <Destinations />
      <Training />
      <Services />
      <Reviews />
      <Form />
    </main>
  );
};

export default MainCover;
