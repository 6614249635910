import React from "react";
import "./Grid.css";

const Grid = ({ name, img1, img2, img3, img4, text, img5 }) => {
  return (
    <ul className="vyh-grid">
      <li className="vyh-grid__item">
        <p className="t-20">{name}</p>
        <img src={img1} alt="name" title={name} />
      </li>
      <li className="vyh-grid__item">
        <p className="t-20">Herna kasina</p>
        <img src={img2} alt="Herna kasina one" title="Herna kasina one" />
      </li>
      <li className="vyh-grid__item">
        <p className="t-20">Herna kasina</p>
        <img src={img3} alt="Herna kasina two" title="Herna kasina two" />
      </li>
      <li className="vyh-grid__item">
        <p className="t-20">Proces učení</p>
        <img src={img4} alt="Proces učení" title="Proces učení" />
      </li>
      <li className="vyh-grid__item text">
        <p>{text}</p>
      </li>
      <li className="vyh-grid__item">
        <p className="t-20">Sloty</p>
        <img src={img5} alt="Sloty" title="Sloty" />
      </li>
    </ul>
  );
};

export default Grid;
