import React, { useState } from "react";
import "./Form.css";
import axios from "axios";
import sendBtn from "../../images/form-arrow.svg";
import { useLocation } from "react-router-dom";

const Form = () => {
  const location = useLocation();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("mail.php", formData)
      .then((response) => {
        console.log("Message send");
      })
      .catch((error) => {
        console.log("Error sending message");
      });
  };

  return (
    <section className="vyh-form">
      <div className="vyh-form__body">
        {location.pathname === "/form" ? (
          <h1 className="vyh-form__header">Stát se krupiérem</h1>
        ) : (
          <h2 className="vyh-form__header">Stát se krupiérem</h2>
        )}
        <p className="t-25 vyh-form__text">
          Uveďte prosím své jméno, kontaktní údaje a krátkou zprávu o vašem
          zájmu o práci krupiéra. Brzy se vám ozveme!
        </p>

        <form onSubmit={handleSubmit} className="vyh-form__form">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Jméno a příjmení"
            className="vyh-form__input t-18"
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Mail"
            className="vyh-form__input t-18"
          />
          <input
            type="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Telefon"
            className="vyh-form__input t-18"
          />
          <input
            name="message"
            type="text"
            value={formData.message}
            onChange={handleChange}
            placeholder="Zprávu"
            className="vyh-form__input t-18"
          />
          <button type="submit" className="vyh-form__btn t-24 col-white">
            <img
              src={sendBtn}
              alt="send button"
              title="send button"
              className="vyh-btn"
            />
          </button>
        </form>
        <p className="t-25 vyh-form__text">
          Naši specialisté vás budou kontaktovat s dalšími informacemi. Odpovíme
          na všechny vaše dotazy a pomůžeme vám nastartovat úspěšnou kariéru v
          kasinu.
        </p>
      </div>
    </section>
  );
};

export default Form;
