import React from "react";
import "./ContactUs.css";
import Form from "../Form/Form";

const ContactUs = () => {
  return (
    <>
      <section className="vyh-contact">
        <h1 className="vyh-main__header col-white text-center">Kontaktujte nás</h1>
        <p className="t-25 vyh-contact__text col-white">
          Adresa:
          <span className="col-yellow text-center">
            Na Příkopě 12, 110 00 Praha, Česká republika
          </span>
        </p>
        <p className="t-25 vyh-contact__text col-white">
        Telefon: 
          <span className="col-yellow text-center">
          +420 728 123 456
          </span>
        </p>
        <p className="t-25 vyh-contact__text col-white">
        Pošta: 
          <span className="col-yellow text-center">
          info@vyhernipecky.com
          </span>
        </p>
      </section>
      <Form />
    </>
  );
};

export default ContactUs;
