import React from "react";
import Destinations from "../Destinations/Destinations";
import "./Country.css";
import Grid from "../Grid/Grid";
import img1 from "../../images/britain-1.webp";
import img2 from "../../images/britain-2.webp";
import img3 from "../../images/britain-3.webp";
import img4 from "../../images/britain-4.webp";
import img5 from "../../images/britain-5.webp";

const Britain = () => {
  return (
    <>
      <section className="vyh-country">
        <h1 className="vyh-country__header text-center col-white">
          Horní 5 Nejlepší kasino Dealer školy ve Velké Británii.
        </h1>
      </section>
      <Destinations />
      <section className="vyh-casinos">
        <h2 className="vyh-casinos__header">Casinos</h2>
        <div className="vyh-casinos__body">
          <ul className="vyh-casinos__list">
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  London Casino Academy
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                London Casino Academy je jednou z předních kasinových škol v
                Londýně, nabízející kurzy pro výcvik krupiérů a manažerů kasin.
                Programy zahrnují výuku her, jako je blackjack, ruleta a poker,
                stejně jako školení v oblasti řízení a vedení kasinového
                podnikání.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Manchester Gaming Institute
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Manchester Gaming Institute nabízí intenzivní kurzy pro ty,
                kteří chtějí začít kariéru v kasinovém průmyslu. Škola poskytuje
                kurzy výcviku krupiérů se zaměřením na praxi a techniky práce s
                kartami a žetony.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Birmingham Casino Training Centre
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Birmingham Casino Training Centre se specializuje na kurzy
                výcviku krupiérů se zaměřením na evropské hry, jako je ruleta a
                baccarat. Škola také nabízí kurzy na zlepšení komunikačních
                dovedností a řízení herních procesů.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Glasgow School of Gaming
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Glasgow School of Gaming poskytuje kurzy pro začínající i
                pokročilé krupiéry. Programy zahrnují výuku různých her a také
                řízení pokladny a finančních operací v kasinu.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Brighton Dealer Academy
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Brighton Dealer Academy je známá svými kurzy pro krupiéry, které
                jsou zaměřené na praktickou výuku a rozvoj rychlých reakcí.
                Škola také nabízí školení v oblasti vytváření pohostinné
                atmosféry a práce se zákazníky.
              </p>
            </li>
          </ul>
          <Grid
            name={"The Hippodrome Casino"}
            img1={img1}
            img2={img2}
            img3={img3}
            img4={img4}
            text="The Hippodrome Casino v Londýně nabízí specializované kurzy pro krupiéry, včetně školení v ruletě, pokeru a dalších populárních her ve Velké Británii."
            img5={img5}
          />
        </div>
      </section>
    </>
  );
};

export default Britain;
