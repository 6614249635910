import React from "react";
import Destinations from "../Destinations/Destinations";
import "../Britain/Country.css";
import Grid from "../Grid/Grid";
import img1 from "../../images/germany-1.webp";
import img2 from "../../images/germany-2.webp";
import img3 from "../../images/germany-3.webp";
import img4 from "../../images/germany-4.webp";
import img5 from "../../images/germany-5.webp";

const Germany = () => {
  return (
    <>
      <section className="vyh-country">
        <h1 className="vyh-country__header text-center col-white">
          Top 5 Nejlepší kasino Dealer školy v Německu.
        </h1>
      </section>
      <Destinations />
      <section className="vyh-casinos">
        <h2 className="vyh-casinos__header">Casinos</h2>
        <div className="vyh-casinos__body">
          <ul className="vyh-casinos__list">
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Berlin Casino Academy
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Berlin Casino Academy nabízí kurzy výcviku krupiérů se zaměřením
                na mezinárodní standardy. Studenti se učí ruletu, poker a
                blackjack a absolvují kurzy o etice práce v kasinu.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Hamburg Gaming Institute
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Hamburg Gaming Institute školí krupiéry a manažery kasin. Kurzy
                zahrnují praktická cvičení u herních stolů a tréninky řízení
                herních procesů.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Munich Dealer School
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Munich Dealer School poskytuje školení krupiérů se zaměřením na
                komunikaci se zákazníky a vytváření atmosféry v kasinu. Program
                zahrnuje také výuku techniky práce s kartami.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Frankfurt Casino Training Center
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Frankfurt Casino Training Center nabízí intenzivní kurzy pro ty,
                kteří chtějí pracovat jako krupiéři. Studenti se učí práci s
                žetony a řízení herních procesů.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Cologne Professional Gaming Academy
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Cologne Professional Gaming Academy školí studenty ve všech
                aspektech práce v kasinu. Kurzy zahrnují teoretické i praktické
                lekce všech populárních her.
              </p>
            </li>
          </ul>
          <Grid
            name={"Casino Baden-Baden"}
            img1={img1}
            img2={img2}
            img3={img3}
            img4={img4}
            text="Casino Baden-Baden, známé svým elegantním stylem, nabízí kurzy pro krupiéry zaměřené na klasické evropské hry."
            img5={img5}
          />
        </div>
      </section>
    </>
  );
};

export default Germany;
