import React from "react";

const Privacy = () => {
  return (
    <main>
      <section className="vyh-privacy">
        <h1 className="privacy__header">
          Zásady ochrany osobních údajů pro vyhernipecky.com
        </h1>
        <p className="t-20">
          Na vyhernipecky.com, přístupném přes vyhernipecky.com, je jednou z
          našich hlavních priorit ochrana soukromí našich návštěvníků. Tento
          dokument o zásadách ochrany osobních údajů obsahuje typy informací,
          které jsou shromažďovány a zaznamenávány na vyhernipecky.com, a jak je
          využíváme.
        </p>
        <p className="t-20">
          Pokud máte další otázky nebo potřebujete více informací o našich
          zásadách ochrany osobních údajů, neváhejte nás kontaktovat.
        </p>
        <h2 className="privacy__header">Soubory protokolu</h2>
        <p className="t-20">
          vyhernipecky.com následuje standardní postup používání souborů
          protokolu. Tyto soubory zaznamenávají návštěvníky při návštěvě webu.
          Všechny hostingové společnosti to dělají a je to součástí analýzy
          služeb hostingu. Informace shromážděné těmito soubory protokolu
          zahrnují IP adresy (internetový protokol), typ prohlížeče,
          poskytovatele internetových služeb (ISP), datum a čas,
          odkazující/výstupní stránky a případně počet kliknutí. Tyto informace
          nejsou spojeny s žádnými osobně identifikovatelnými údaji. Účelem
          těchto informací je analýza trendů, správa webu, sledování pohybu
          uživatelů na webu a shromažďování demografických informací. Naše
          zásady ochrany osobních údajů byly vytvořeny pomocí generátoru zásad
          ochrany osobních údajů.
        </p>
        <h2 className="privacy__header">Naši reklamní partneři</h2>
        <p className="t-20">
          Někteří z inzerentů na našem webu mohou používat soubory cookie a
          webové majáky. Naši reklamní partneři jsou uvedeni níže. Každý z
          těchto reklamních partnerů má své vlastní zásady ochrany osobních
          údajů týkající se uživatelských údajů. Pro snadnější přístup jsme
          poskytli odkazy na jejich zásady ochrany osobních údajů níže.
        </p>
        <h2 className="privacy__header">Zásady ochrany osobních údajů</h2>
        <p className="t-20">
          Tento seznam můžete použít k nalezení zásad ochrany osobních údajů
          jednotlivých reklamních partnerů vyhernipecky.com.
        </p>
        <p className="t-20">
          Servery pro reklamy nebo reklamní sítě třetích stran používají
          technologie, jako jsou cookies, JavaScript nebo webové majáky, které
          se používají v příslušných reklamách a odkazech zobrazovaných na
          vyhernipecky.com a které jsou přímo zasílány do prohlížeče uživatele.
          Když k tomu dojde, automaticky obdrží vaši IP adresu. Tyto technologie
          se používají k měření efektivity jejich reklamních kampaní a/nebo k
          personalizaci reklamního obsahu, který vidíte na webových stránkách,
          které navštěvujete.
        </p>
        <p className="t-20">
          Upozorňujeme, že vyhernipecky.com nemá přístup ani kontrolu nad těmito
          cookies, které používají inzerenti třetích stran.
        </p>
        <h2 className="privacy__header">
          Zásady ochrany osobních údajů třetích stran
        </h2>
        <p className="t-20">
          Zásady ochrany osobních údajů vyhernipecky.com se nevztahují na jiné
          inzerenty nebo webové stránky. Proto vám doporučujeme, abyste se
          seznámili se zásadami ochrany osobních údajů těchto třetích stran pro
          podrobnější informace. Tyto zásady mohou zahrnovat jejich praktiky a
          pokyny o tom, jak zrušit některé možnosti.
        </p>
        <p className="t-20">
          Můžete zakázat cookies prostřednictvím možností svého prohlížeče. Pro
          podrobnější informace o správě cookies v konkrétních webových
          prohlížečích navštivte příslušné webové stránky prohlížečů. Co jsou to
          cookies?
        </p>
        <h2 className="privacy__header">Informace pro děti</h2>
        <p className="t-20">
          Další prioritou je ochrana dětí při používání internetu. Doporučujeme
          rodičům a opatrovníkům, aby sledovali, účastnili se a/nebo
          monitorovali a řídili jejich online aktivity.
        </p>
        <p className="t-20">
          vyhernipecky.com vědomě neshromažďuje žádné osobní údaje
          identifikovatelné od dětí mladších 13 let. Pokud se domníváte, že vaše
          dítě poskytlo takové informace na našich stránkách, důrazně vám
          doporučujeme nás ihned kontaktovat a my se budeme snažit co nejdříve
          tyto informace odstranit z našich záznamů.
        </p>
        <h2 className="privacy__header">
          Zásady ochrany osobních údajů pouze online
        </h2>
        <p className="t-20">
          Tyto zásady ochrany osobních údajů platí pouze pro naše online
          aktivity a jsou platné pro návštěvníky našich webových stránek, pokud
          jde o informace, které sdíleli a/nebo shromáždili na vyhernipecky.com.
          Tyto zásady se nevztahují na žádné informace shromážděné offline nebo
          prostřednictvím jiných kanálů než tohoto webu.
        </p>
        <h2 className="privacy__header">Souhlas</h2>
        <p className="t-20">
          Používáním našich webových stránek souhlasíte s našimi zásadami
          ochrany osobních údajů a souhlasíte s jejich podmínkami.
        </p>
      </section>
    </main>
  );
};

export default Privacy;
