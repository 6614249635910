import { Link } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { useState, useEffect } from "react";

function Header() {
  const [isActive, setIsActive] = useState(false);
  const [date, setDate] = useState("");

  function handleClick() {
    setIsActive(false);
  }

  function updateDateTime() {
    const todayDate = new Date();

    // Locale-specific date and time
    const formattedDateTime = todayDate.toLocaleString("cs", {
      year: "2-digit",
      month: "2-digit", // numeric representation of the month
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    setDate(formattedDateTime);
  }

  useEffect(() => {
    updateDateTime();

    const intervalId = setInterval(updateDateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="vyh-header__container">
      <div className="vyh-header__top-container">
        <p className="vyh-date col-yellow">{date}</p>
        <p className="vyh-header__top-text col-yellow">
          Top 5 nejlepších škol kasinových dealerů v Evropě.
        </p>
      </div>
      <div className="vyh-header__link-container">
        <Link to="/" className="vyh-header__link">
          Domovská stránka
        </Link>
        <Link to="/training" className="vyh-header__link">
          Školení
        </Link>
        <Link to="/our-services" className="vyh-header__link">
          Naše služby
        </Link>
        <Link to="/reviews" className="vyh-header__link">
          Reference
        </Link>
        <Link
          to="https://www.casinomeister.com/forums/"
          target="_blank"
          className="vyh-header__link"
        >
          Fórum
        </Link>
        <Link to="/contact-us" className="vyh-header__link">
          Kontaktujte nás
        </Link>
      </div>

      <div className="burger-menu">
        <div id="burger-menu">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            className="vyh-burger__icon"
            onClick={() => setIsActive(true)}
          />
        </div>
        <nav
          className={`burger-menu__nav ${isActive ? "active" : ""}`}
          id="nav"
        >
          <img
            src={closeBtn}
            className="burger__close-btn"
            id="close-icon"
            alt="close menu icon"
            onClick={handleClick}
          />
          <div className="burger-menu__nav-container">
            <Link to="/" className="vyh-header__link" onClick={handleClick}>
              Domovská stránka
            </Link>
            <Link
              to="/training"
              className="vyh-header__link"
              onClick={handleClick}
            >
              Školení
            </Link>
            <Link
              to="/our-services"
              className="vyh-header__link"
              onClick={handleClick}
            >
              Naše služby
            </Link>
            <Link
              to="/reviews"
              className="vyh-header__link"
              onClick={handleClick}
            >
              Reference
            </Link>
            <Link
              to="https://www.casinomeister.com/forums/"
              target="_blank"
              className="vyh-header__link"
              onClick={handleClick}
            >
              Fórum
            </Link>
            <Link
              to="/contact-us"
              className="vyh-header__link"
              onClick={handleClick}
            >
              Kontaktujte nás
            </Link>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
