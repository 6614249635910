import React from "react";
import { useLocation } from "react-router-dom";
import "./Training.css";

const Training = () => {
  const location = useLocation();

  return (
    <section className="vyh-training">
      {location.pathname === "/training" ? (
        <h1 className="vyh-training__header">
          Chcete se stát profesionálním krupiérem?
        </h1>
      ) : (
        <h2 className="vyh-training__header">
          Chcete se stát profesionálním krupiérem?
        </h2>
      )}
      <p className="t-20">
        Krupiér je povolání spojené s prací v kasinu nebo herním zařízení.
        Hlavním úkolem krupiéra je řízení hazardních her, jako je ruleta,
        blackjack, poker a výherní automaty. Aby se člověk stal krupiérem, musí
        projít speciálním školením. Školení krupiéra zahrnuje studium pravidel a
        strategií her, nácvik technik práce s kartami a žetony a také rozvoj
        komunikačních dovedností při jednání se zákazníky. Kromě toho musí být
        krupiér pozorný a pohotový, aby efektivně vedl hry a zpracovával sázky.
      </p>
      <p className="t-20">
        Školení krupiérů může probíhat ve speciálních výukových zařízeních nebo
        přímo v kasinu. Během školení studenti získávají teoretické znalosti a
        praktické dovednosti, které jim pomohou úspěšně pracovat v tomto oboru.
        Po dokončení školení mohou krupiéři najít práci v kasinu nebo herním
        zařízení. Plat krupiéra závisí na mnoha faktorech, včetně zkušeností,
        lokalitě a velikosti kasina. Kromě toho mohou krupiéři dostávat
        spropitné od zákazníků.
      </p>
      <p className="t-20">
        Školení pro krupiéry může být dobrou volbou pro ty, kdo se zajímají o
        hazardní hry a chtějí pracovat v prostředí kasina. Toto povolání
        vyžaduje určité dovednosti a vlastnosti, ale může být velmi vzrušující a
        výnosné.
      </p>
    </section>
  );
};

export default Training;
