import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import age from "../../images/age.svg";

const Footer = () => {
  return (
    <footer id="contacts" className="vyh-footer">
      <div className="vyh-footer__body">
        <div className="vyh-footer__el1">
          <img
            src={age}
            alt="age limit 18+"
            title="age limit 18+"
            width="54"
            height="54"
          />
          <Link to="/privacy" className="t-15 vyh-footer__link col-white">
            Soukromí
          </Link>
        </div>
        <div className="vyh-footer__text-container">
          <p className="t-15 col-white">
            vyhernipecky.com používá cookies, které vám zaručují maximální
            pohodlí. Pokud zůstanete na webu, souhlasíte s používáním vašich
            cookies.
          </p>
          <Link to="/cookies" className="vyh-footer__link col-white">
            Dozvědět se více
          </Link>
        </div>
        <p className="t-15 col-white">
          Disklaimer:všechny informace uvedené na našich stránkách jsou určeny
          výhradně pro informační účely
        </p>
      </div>
    </footer>
  );
};

export default Footer;
