import React from "react";
import { useLocation } from "react-router-dom";
import "./Services.css";

const Services = () => {
  const location = useLocation();
  return (
    <section className="vyh-services">
      {location.pathname === "/our-services" ? (
        <h1 className="vyh-services__header">Naše služby</h1>
      ) : (
        <h2 className="vyh-services__header">Naše služby</h2>
      )}
      <div className="vyh-services__text-container">
        <p className="t-20">
          <span className="fw-500">Večírky a soukromé akce</span>
          <br></br>
          Organizace a realizace kasinových show: konference, firemní a soukromé
          akce, show programy a mnohem více! Vše je organizováno na vysoké
          profesionální úrovni s důrazem na detaily našimi odborníky v oblasti
          kasinového průmyslu. Nabízíme profesionální animaci, která zanechá v
          účastnících nezapomenutelné zážitky a originální vzpomínky.
        </p>
        <p className="t-20">
          <span className="fw-500">Elegance a kouzlo kasina</span>
          vždy fascinovaly naše hosty. Vytváříme luxusní scénické dekorace s
          použitím kvalitních materiálů a uměním skutečných profesionálů u
          herních stolů. Každý náš projekt je kombinací vytříbeného stylu a
          vzrušující zábavy, která zanechává nezapomenutelný dojem.
        </p>
      </div>
    </section>
  );
};

export default Services;
