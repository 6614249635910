import React from "react";
import Destinations from "../Destinations/Destinations";
import "../Britain/Country.css";
import Grid from "../Grid/Grid";
import img1 from "../../images/italy-1.webp";
import img2 from "../../images/italy-2.webp";
import img3 from "../../images/italy-3.webp";
import img4 from "../../images/italy-4.webp";
import img5 from "../../images/italy-5.webp";

const Italy = () => {
  return (
    <>
      <section className="vyh-country">
        <h1 className="vyh-country__header text-center col-white">
          Horní 5 Nejlepší kasino Dealer školy vv Itálii.
        </h1>
      </section>
      <Destinations />
      <section className="vyh-casinos">
        <h2 className="vyh-casinos__header">Casinos</h2>
        <div className="vyh-casinos__body">
          <ul className="vyh-casinos__list">
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Milano Casino Academy
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Milano Casino Academy nabízí výukové programy pro krupiéry se
                zaměřením na italské hry, jako je baccarat a sicilská ruleta.
                Studenti absolvují intenzivní tréninky techniky práce s kartami
                a žetony.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Rome Gaming School
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                V Rome Gaming School se studenti učí různé aspekty práce v
                kasinu, včetně řízení stolů a finančních operací. Programy
                zahrnují také výuku komunikačních dovedností se zákazníky.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Venice Dealer Institute
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Venice Dealer Institute se zaměřuje na výuku krupiérů evropským
                hrám, jako je blackjack a ruleta. Škola nabízí kurzy se
                zaměřením na praktickou výuku.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Casino Florence Training Center
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Casino Florence Training Center nabízí kurzy pro krupiéry,
                včetně tréninků na skutečných herních stolech a školení pro
                řízení her v multiplayerových režimech.
              </p>
            </li>
            <li className="vyh-casinos__item">
              <div className="vyh-casinos__container">
                <h2 className="vyh-casinos__small-header">
                  Turin Professional Gaming School
                </h2>
              </div>
              <p className="t-20 vyh-casinos__text">
                Turin Professional Gaming School připravuje studenty na kariéru
                krupiéra, zaměřuje se na disciplínu, profesionalitu a školení v
                jemnostech práce v kasinu.
              </p>
            </li>
          </ul>
          <Grid
            name={"Casino di Venezia"}
            img1={img1}
            img2={img2}
            img3={img3}
            img4={img4}
            text="Casino di Venezia, nejstarší kasino na světě, nabízí školení pro krupiéry ve svém výukovém centru. Studenti se učí pravidla rulety, blackjacku a dalších her populárních v Evropě."
            img5={img5}
          />
        </div>
      </section>
    </>
  );
};

export default Italy;
